import { DESTINATION_TYPE } from '../const';
import { isDestinationAllValue } from '../../../helpers/helper-methods';

export const mapperDestinationTree = ({ data }) => {
  const updatedList = data?.filter(({ code }) => !code.endsWith('-00')).map((element) => (getValueForSelect(element)));
  return updatedList;
};

export const addIsLargeProps = (destination) => (destination?.hasOwnProperty('isLarge') && { isLarge: destination?.isLarge });

export const transformSelectedValue = (selectedValue) => getValueForSelect(selectedValue);

export const getValueForSelect = (value) => ({
  label: value?.name,
  value: value?.id,
  ...(addIsLargeProps(value)),
});

export const getValueForDestination = (value) => ({
  id: value.value,
  name: value.label,
  ...(addIsLargeProps(value)),
});

export const getDestinationType = (district, city, region) => {
  if (district) return DESTINATION_TYPE.district;
  if (city) return DESTINATION_TYPE.city;
  if (region) return DESTINATION_TYPE.region;
  return DESTINATION_TYPE.country;
};

export const filterDestinationsAllValues = (destinations) => destinations.filter(({ code, region }) => !isDestinationAllValue(region?.city?.district?.code)
    && !isDestinationAllValue(region?.city?.code)
    && !isDestinationAllValue(region?.code)
    && !isDestinationAllValue(code));

export const getDestinationValue = (fieldName, country, region, city, district) => district?.[fieldName] || city?.[fieldName] || region?.[fieldName] || country?.[fieldName];

export const sortDestinationByKey = (destinations = [], key = 'title') => destinations.sort((destinationA, destinationB) => {
  if (destinationA[key] < destinationB[key]) { return -1; }
  if (destinationA[key] > destinationB[key]) { return 1; }
  return 0;
});
