import http from './http.service';

const destinationsService = () => {
  const getDestinations = (model) => http.get('/destinations', { params: model });
  const getDestinationProperties = (model) => http.get('/destinations/properties', { params: model });
  const getDestinationActivities = (model) => http.get('/destinations/activities', { params: model });

  const getCountries = () => http.get('/countries');
  const getRegions = (countryId) => http.get('/regions', { params: { countryId } });
  const getCities = (regionId) => http.get('/cities', { params: { regionId } });
  const getDistricts = (cityId) => http.get('/districts', { params: { cityId } });

  return {
    getDestinations,
    getCountries,
    getRegions,
    getCities,
    getDistricts,
    getDestinationProperties,
    getDestinationActivities,
  };
};

export default destinationsService;
