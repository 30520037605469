import React, { useMemo } from 'react';
import isEqual from 'lodash.isequal';
import Select, { components } from 'react-select';
import { useField } from 'formik';
import Error from '../Error/Error';
import SelectDropIcon from '../../../assets/img/icons/angle-arrow-down.svg';
import styles from './CustomSelect.module.scss';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SelectDropIcon />
  </components.DropdownIndicator>
);

const CustomSelect = ({
  containerStyles,
  customStylesFunc,
  selectClasses,
  hideError,
  onChange,
  disabled,
  menuListStylesFunc,
  ...props
}) => {
  const [, meta, helpers] = useField(props);

  const { setValue, setTouched, setError } = helpers;
  const setFieldProps = (selectedOption) => {
    setValue(selectedOption.value);
    setError(null);
  };
  const customStylesObj = customStylesFunc
    ? customStylesFunc(meta.error, meta.touched)
    : null;

  const handleChange = (selectedOption) => {
    setFieldProps(selectedOption);
    onChange(selectedOption);
  };

  const defaultStyles = useMemo(
    () => ({
      control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#f5f5f5' : 'transparent',
        outline: 'none',
        fontWeight: '500',
        border:
          meta.touched && !!meta.error
            // Error color
            ? '1px solid #e94949'
            : state.selectProps.menuIsOpen || state.isFocused || state.isSelected
              ? '1px solid #2684ff'
              : '1px solid #dbe2ea',
        borderRadius: '6px',
        boxShadow: state.selectProps.menuIsOpen || state.isFocused || state.isSelected
          ? '0 0 0px 1px #2684ff'
          : 'none',
        '&:active': {
          border: '1px solid #2684ff',
          boxShadow: '0 0 0px 1px #2684ff',
          cursor: 'pointer',
        },
        '&:hover': {
          border: '1px solid #2684ff',
          cursor: 'pointer',
          boxShadow: '0 0 0px 1px #2684ff',
        },
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        marginRight: '7px',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
        transition: 'all 0.3s',
        '& svg': {
          fill: '#2684ff',
          width: '16px',
          height: '16px',
        },
      }),
      option: (provided, state) => ({
        ...provided,
        textAlign: 'left',
        transition: 'all 0.3s ease-out',
        backgroundColor: state.isFocused ? 'rgba(2,170,255,0.05)' : 'transparent',
        cursor: 'pointer',
        color: state.isSelected
          ? '#02AAFF'
          : state.isFocused && '#2B2B2B',
        fontSize: '16px',
        fontWeight: '500',
        '&:hover': {
          backgroundColor: 'rgba(2,170,255,0.05)',
        },
      }),
      menu: (provided, state) => ({
        ...provided,
        padding: '10px 0',
        marginTop: '10px',
        borderRadius: '6px',
        zIndex: 6,
        border: '1px solid #dbe2ea',
        boxShadow: state.selectProps.menuIsOpen
          ? '0 20px 20px 0 rgba(44, 39, 56, 0.04), 0 4px 8px 0 rgba(44, 39, 56, 0.04)'
          : '',
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '11px 15px',
        maxHeight: '47px',
      }),
      singleValue: (_, state) => ({
        color: state.isDisabled ? 'rgba(43, 43, 43, 0.2)' : 'rgba(43, 43, 43, 0.6)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }),
      placeholder: (_, state) => ({
        color: state.isDisabled ? 'rgba(43, 43, 43, 0.2)' : 'rgba(43, 43, 43, 0.4)',
        fontWeight: '500',
      }),
      menuList: menuListStylesFunc || ((provided, state) => ({
        ...provided,
        padding: 0,
        maxHeight: '164px',
        overflow: 'auto',
        zIndex: state.selectProps.menuIsOpen ? 5 : 'auto',
      })),
    }),
    [meta.error, meta.touched],
  );
  return (
    <div className={`${containerStyles || ''} ${styles.inputContainer}`}>
      <Select
        // tabIndex={0}
        // defaultMenuIsOpen
        isDisabled={disabled}
        styles={customStylesObj || defaultStyles}
        className={selectClasses}
        isSearchable={false}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        {...props}
        // eslint-disable-next-line react/destructuring-assignment
        value={props.options.filter(({ value }) => {
          if (typeof meta.value === 'object') {
            return isEqual(value, meta.value);
          }
          return value === meta.value;
        })}
        onBlur={setTouched}
        onChange={(selectedOption) => handleChange(selectedOption)}
      />
      {!hideError ? meta.touched && <Error error={meta.error} /> : null}
    </div>
  );
};

CustomSelect.defaultProps = {
  customStylesFunc: null,
  hideError: false,
  disabled: false,
  menuListStylesFunc: null,
  onChange: () => { /* do nothing */ },
};

export default CustomSelect;
