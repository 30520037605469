export const COLOR_TREE = {
  country: '#2684FF',
  region: '#FFC400',
  city: '#D32452',
  district: '#C1C7D0',
};

export const DESTINATION_TYPE = {
  country: 'country',
  region: 'region',
  city: 'city',
  district: 'district',
};
