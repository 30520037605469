import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Input } from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import InputPassword from '../../components/UI/InputPassword/InputPassword';
import Label from '../../components/UI/Label/Label';
import InputWrapper from '../../components/UI/InputWrapper/InputWrapper';
import {
  passwordValidationSchema,
  emailValidationSchema,
  fullNamedValidationSchema, countryValidationSchema,
} from '../../constans/validation-schemas';
import { AUTH } from '../../constans/form-fields';
import {
  PASSWORD_INVALID_TIP,
  PASSWORD_VALID,
} from '../../constans/error-messages';

import classes from './index.module.scss';
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect';
import { mapperDestinationTree } from '../../components/DestinationTree/helper';
import DestinationsService from '../../services/destinationsService';

const initialValues = {
  email: AUTH.email.initialValue,
  password: AUTH.password.initialValue,
  firstName: AUTH.firstName.initialValue,
  lastName: AUTH.lastName.initialValue,
  country: AUTH.country.initialValue,
};

const validationSchema = Yup.object({
  password: passwordValidationSchema,
  firstName: fullNamedValidationSchema,
  lastName: fullNamedValidationSchema,
  country: countryValidationSchema,
}).concat(emailValidationSchema);

const destinationsService = DestinationsService();

function SignUpForm({ formSubmitHandler }) {
  const [countryList, setCountryList] = useState([]);

  const loadOptionsCountriesList = useCallback(() => {
    setCountryList([]);

    destinationsService.getCountries()
      .then(mapperDestinationTree)
      .then((updatedList) => {
        setCountryList(updatedList);
      });
  }, [setCountryList]);

  useEffect(() => {
    loadOptionsCountriesList();
  }, []);

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={formSubmitHandler}
    >
      {({ isValid, errors }) => (
        <Form>
          <InputWrapper containerClasses={classes.inputBlock}>
            <Label containerStyles={classes.label}>First Name</Label>
            <Input
              type="text"
              name={AUTH.firstName.name}
              placeholder={AUTH.firstName.placeholder}
            />
          </InputWrapper>
          <InputWrapper containerClasses={classes.inputBlock}>
            <Label containerStyles={classes.label}>Last Name</Label>
            <Input
              type="text"
              name={AUTH.lastName.name}
              placeholder={AUTH.lastName.placeholder}
            />
          </InputWrapper>
          <InputWrapper containerClasses={classes.inputBlock}>
            <Label containerStyles={classes.label}>Email</Label>
            <Input
              type="email"
              name={AUTH.email.name}
              placeholder={AUTH.email.placeHolder}
            />
          </InputWrapper>
          <InputWrapper containerClasses={classes.inputBlock}>
            <Label containerStyles={classes.label}>Password</Label>
            <div>
              <InputPassword
                name={AUTH.password.name}
                placeholder={AUTH.password.placeHolder}
              />
              <div className={classes.passwordTip}>
                {errors.password ? (
                  PASSWORD_INVALID_TIP
                ) : (
                  <span className="success-color">
                    {PASSWORD_VALID}
                  </span>
                )}
              </div>
            </div>
          </InputWrapper>
          <InputWrapper containerClasses={classes.inputBlock}>
            <Label containerStyles={classes.label}>Country of residence</Label>
            <CustomSelect
              hideError
              name={AUTH.country.name}
              placeholder={AUTH.country.placeHolder}
              options={countryList}
            />
          </InputWrapper>
          <Button
            disabled={!isValid}
            classes=" btn btn_block btn-uppercased btn_common btn_auth t-600"
            type="submit"
          >
            Sign Up
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default SignUpForm;
